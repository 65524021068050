import WinCom from "../../common/util/WinCom";
var winCom = null;
var init = function () {
    if (winCom) {
        return;
    }
    winCom = new WinCom("extMicrositeId");
    winCom.connect(window, window.top);
    winCom.post(["content-ready", document.body.scrollHeight]);
    document.body.style.overflowY = "hidden";
    window.addEventListener("resize", function () { if (winCom) {
        winCom.post(["change-height", document.body.scrollHeight]);
    } });
};
var initIfNotLoaded = function () {
    window.setTimeout(function () {
        init();
    }, 3000);
};
if (document.readyState === "complete" || document.readyState === "interactive") {
    initIfNotLoaded();
}
else {
    document.addEventListener("DOMContentLoaded", initIfNotLoaded);
}
window.onload = function () { init(); };
