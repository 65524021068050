var WinCom = /** @class */ (function () {
    function WinCom(id) {
        var _this = this;
        this._onMessage = function (event) {
            if (event.data && typeof event.data != "object") {
                return;
            }
            if (event.data.id !== _this.id) {
                return;
            }
            if (event.data.internalId == _this.internalId) {
                return;
            }
            if (event.data.type == "connection-created") {
                _this._sendMessage("connection-accepted");
                _this.connected = true;
                _this._processQueue();
                return;
            }
            else if (event.data.type == "connection-accepted") {
                _this.connected = true;
                _this._processQueue();
                return;
            }
            if (_this.cb.onMessage) {
                _this.cb.onMessage(event.data);
            }
        };
        this.id = id;
        this.internalId = Date.now() - Math.round((Math.random() * 10000));
        this.cb = {
            onMessage: null,
        };
        this.queue = [];
        this.receiverWin = null;
        this.broadcastWin = null;
        this.connected = false;
    }
    WinCom.prototype.connect = function (receiverWin, broadcastWin) {
        this.receiverWin = receiverWin;
        this.broadcastWin = broadcastWin;
        this.receiverWin.addEventListener("message", this._onMessage);
        this._sendMessage("connection-created");
    };
    WinCom.prototype.onMessage = function (cb) {
        this.cb.onMessage = cb;
    };
    WinCom.prototype.post = function (message) {
        var _this = this;
        if (!this.connected) {
            var args_1 = [].slice.call(arguments);
            this.queue.push(function () {
                // eslint-disable-next-line prefer-spread
                return _this.post.apply(_this, args_1);
            });
            return;
        }
        this._sendMessage(message);
    };
    WinCom.prototype._sendMessage = function (message) {
        message = [].concat(message);
        var messageType = message[0];
        var messageData = message[1];
        try {
            var message_1 = this._getMessage(messageType, messageData);
            if (this.broadcastWin) {
                this.broadcastWin.postMessage(message_1, "*");
            }
        }
        catch (e) {
            console.error("Can not send this command");
        }
    };
    WinCom.prototype._getMessage = function (type, data) {
        if (arguments.length < 2) {
            data = null;
        }
        return {
            type: type,
            data: data,
            internalId: this.internalId,
            id: this.id,
        };
    };
    WinCom.prototype._processQueue = function () {
        while (this.queue.length) {
            var cb = this.queue.shift();
            if (cb) {
                cb();
            }
        }
    };
    return WinCom;
}());
export default WinCom;
